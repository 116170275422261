define("discourse/plugins/discourse-review-plugin/discourse/templates/connectors/category-heading/review", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.category.name}}
    {{#if (includes @outletArgs.category.name "Reviews")}}
  
      {{siteSettings.review_form_intro}}
      <br />
  
      <ReviewForm />
  
      <hr />
  
    {{/if}}
  {{/if}}
  */
  {
    "id": "sOoBzhbG",
    "block": "[[[41,[30,1,[\"category\",\"name\"]],[[[41,[28,[37,1],[[30,1,[\"category\",\"name\"]],\"Reviews\"],null],[[[1,\"\\n    \"],[1,[30,0,[\"siteSettings\",\"review_form_intro\"]]],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[8,[39,2],null,null,null],[1,\"\\n\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n\\n\"]],[]],null]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-review-plugin/discourse/templates/connectors/category-heading/review.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@outletArgs\"],false,[\"if\",\"includes\",\"review-form\"]]",
    "moduleName": "discourse/plugins/discourse-review-plugin/discourse/templates/connectors/category-heading/review.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});