define("discourse/plugins/discourse-review-plugin/discourse/models/review", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    updateProperties() {
      return this.getProperties("name", "email", "review_text");
    }
  });
});